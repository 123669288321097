import React, { useState, useEffect, useRef } from "react";
import Axios, { CancelTokenSource } from "axios";
import { IManageReservationCardProps } from "./ManageReservationCardProps";
import { Container } from "./ManageReservationCardHelpers";

import { Link, navigate } from "gatsby";
import filter from "lodash/filter";
import moment from "moment-mini";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import fallbackImage from "../../../images/hotel_fallback.jpg";
import { fetchReservationDetails } from "../../../services/crs";
import {
  resParseDateFormat,
  resDisplayDateFormat,
} from "../../../services/dates";
import { useHotels } from "../../../hooks/useHotels";
import EditReservationButton from "../EditReservationButton/EditReservationButton";
import { useAppSelector } from "../../../hooks";
import { priceFormatter } from "../../../utils/helpers";
import { checkUsedRedemptionPointsforReservation } from "../../../services/redemption";
import { TP_REDEMPTION_RATE } from "../../../@types/Constants";

const ManageReservationCard = (props: IManageReservationCardProps) => {
  const { reservation, type, onCancel, memberId } = props;
  const mountedRef = useRef(false);
  const member = useAppSelector((state: { member: any }) => state.member);
  const [resServices, setResServices] = useState([]);
  const [isLoadingDetail, setIsloadingDetail] = useState(false);
  const [resApiDetails, setResApiDetails] = useState(null);
  const [pointsUsed, setPointsUsed] = useState(null);
  const guestCount = reservation?.details?.count
    ? reservation?.details?.count
    : {};
  const isRedemptionReservation =
    reservation &&
    reservation?.details?.ratePlanCode &&
    TP_REDEMPTION_RATE.includes(reservation?.details?.ratePlanCode);
  const [resDetails, setResDetails] = useState({
    checkInTime: "",
    checkOutTime: "",
    bedDesc: "",
    roomRates: null,
    cancelPolicies: {},
    cancelID: null,
  });
  const {
    checkInTime,
    checkOutTime,
    bedDesc,
    roomRates,
    cancelPolicies,
    cancelID,
  } = resDetails;
  const allHotels = useHotels();

  const [hotel, setHotel] = useState(reservation.hotel_details);
  const [hotelDisplayData, setHotelDisplayData] = useState({
    hotelName: "",
    address: "",
    addressMapLink: "",
    phone: null,
    imageUrl: fallbackImage,
  });

  const hrMemberlastName = useAppSelector((state: { member: any }) => {
    const member = state.member;
    return member && member.isLoggedIn && member.crmProfile
      ? member.crmProfile.lastName
      : null;
  });

  const lastName = hrMemberlastName || props.lastName;

  const stayDateStr = (() => {
    if (reservation.arrival) {
      const arrival = moment(reservation.arrival, resParseDateFormat);
      const departure = moment(reservation.departure, resParseDateFormat);
      const numberNights = departure.diff(arrival, "days");

      const nightStr = numberNights > 1 ? " Nights" : " Night";
      const displayArrival = arrival.format(resDisplayDateFormat);
      const displayDeparture = departure.format(resDisplayDateFormat);
      return `${displayArrival} - ${displayDeparture} (${numberNights}${nightStr})`;
    } else {
      return "";
    }
  })();

  const totalCostStr = (() => {
    if (roomRates) {
      const resDetailsRates =
        resDetails && resDetails.roomRates && resDetails.roomRates[0];
      const totalCost = resDetailsRates
        ? resDetailsRates.Total || resDetailsRates.total
        : 0;
      const currency =
        resDetailsRates?.CurrencyCode || resDetailsRates?.currencyCode || "";
      if (totalCost > 0) {
        return priceFormatter(totalCost, 2, currency);
      }
    }
    return null;
  })();

  const handleCancelReservation = () => {
    onCancel({
      openDialog: true,
      res: {
        ...reservation,
        ...{ cancelPolicies: cancelPolicies },
        ...{ hotel_details: hotel },
      },
    });
  };

  const getDetails = async (source: CancelTokenSource) => {
    mountedRef.current && setIsloadingDetail(true);
    try {
      let details;
      if (reservation.details && reservation.details.policies) {
        // if details already passed from reservation page then don't need to fetch again
        details = reservation.details;
      } else {
        details = await fetchReservationDetails({
          resId: reservation.crs_reservation_id,
          hotelCode: hotel
            ? hotel.id
            : reservation.details && reservation.details.HotelCode,
          lastName: lastName,
          source: source,
        });
        details = details.value;
        // onReservationDetailLoad && onReservationDetailLoad(reservation.crs_reservation_id, details);
      }

      if (mountedRef.current) {
        if (details) {
          setResApiDetails(details);
          setResDetails({
            checkInTime: details.policies ? details.policies.checkInTime : "",
            checkOutTime: details.policies ? details.policies.checkOutTime : "",
            // bedDesc: details.RoomDescription && details.RoomDescription.Occupancy ? `${details.RoomDescription.Occupancy} ${details.RoomDescription.Name}` : '' ,
            bedDesc: details.roomDescription
              ? details.roomDescription.name
              : "",
            roomRates: details.roomRates,
            cancelPolicies: details.cancelPolicies || {},
            cancelID: details.cancelId,
          });
          if (details.services && details.services.length > 0) {
            const hotelServices: any = [];
            const services = details.services.map((service: any) => {
              if (!hotelServices.includes(service.description)) {
                hotelServices.push(service.description);
              }
            });
            setResServices(hotelServices);
          }
        }
        // add hotel details if hotel info was not initially available from fetchContact api response
        const hotelCode = details && details.hotel.id; //'12667';
        if (!hotel && hotelCode) {
          const reservedHotel = filter(allHotels, (h, key) => {
            return hotelCode == h.crs_code;
          });
          reservedHotel[0] && setHotel(reservedHotel[0]);
        }
        setIsloadingDetail(false);
      }
    } catch (e) {
      mountedRef.current && setIsloadingDetail(false);
    }
  };

  useEffect(() => {
    mountedRef.current = true;
    const source = Axios.CancelToken.source();

    if (reservation) {
      if (mountedRef.current && !isLoadingDetail) {
        // (async () => {
        getDetails(source);
        // })();
      }
    }

    // Cleanup on unmount.
    return () => {
      mountedRef.current = false;
      source.cancel(
        `Request cancelled for ${reservation.crs_reservation_id} details`
      );
    };
  }, []);

  useEffect(() => {
    if (member.isLoggedIn) {
      const userUpcomingStays = member.upcomingReservations;
      const reservationId = reservation.crs_reservation_id;
      const pointUsedByUser = checkUsedRedemptionPointsforReservation(
        reservationId,
        userUpcomingStays
      );
      if (pointUsedByUser && pointUsedByUser != 0) {
        setPointsUsed(pointUsedByUser);
      }
    }
  }, []);

  useEffect(() => {
    if (hotel) {
      const address = hotel.address;
      const imageUrl =
        hotel.banner_images && hotel.banner_images.length
          ? hotel.banner_images[0].url
          : fallbackImage;
      const addressStr = address
        ? `${address.address_line1 || ""} ${address.address_line2 || ""} ${
            address.locality || ""
          }, ${address.administrative_area || ""}, ${
            address.country_code || ""
          } ${address.postal_code || ""}`
        : "";
      const addressParsed = addressStr.replace(/(<([^>]+)>)/gi, "").split(", ");
      const addressUrl = addressParsed.join("+").replace(/s/, "+");
      setHotelDisplayData({
        hotelName: hotel.name || "",
        address: addressStr,
        addressMapLink: `https://www.google.com/maps/dir/?api=1&destination=${addressUrl}`,
        phone: hotel.phone || null,
        imageUrl: imageUrl || fallbackImage,
      });
      // onHotelDisplay && onHotelDisplay(hotel);
    }
  }, [hotel]);

  useEffect(() => {
    if (
      type === "cancelled" &&
      props.reservation &&
      props.reservation.details &&
      props.reservation.details.CancelID
    ) {
      setResDetails({
        ...resDetails,
        cancelID: props.reservation.details.CancelID,
        roomRates: props.reservation.details.roomRates,
      });
    }
  }, [type]);

  // useEffect(() => {
  //   if(type === 'upcoming' && cancelID) {
  //     // if reservation is upcoming as per fetchcontact api response but actually it's cancelled then move this reservation from upcoming res list to cancelled list.
  //     // setTimeout(function() {
  //     //   dispatch({
  //     //     type: 'CANCEL_RESERVATION',
  //     //     payload: reservation.crs_reservation_id,
  //     //   });
  //     // },0)
  //   }
  // },[type, cancelID])
  const addressArray = hotelDisplayData.address.split(", ");
  const nonNullAddressArray = addressArray.filter(Boolean);
  const addressWithoutNull = nonNullAddressArray.join(", ");

  return (
    <Container>
      <Row className="hotel-name-row">
        <div className="mb-2 hotel-name">
          {hotel && hotel.path ? (
            <Link to={hotel.path.alias}>{hotelDisplayData.hotelName}</Link>
          ) : (
            hotelDisplayData.hotelName
          )}
        </div>
        <div className="p-0">
          <span className="labels">Reservation ID: </span>
          <span className="reservation-id" id="reservation-id">
            {reservation.crs_reservation_id}
          </span>
        </div>
        {cancelID && (
          <div className="cancelled p-0">
            <span className="labels">Cancellation ID: </span>
            <span className="reservation-id" id="cancellation-id">
              {cancelID}
            </span>
          </div>
        )}
      </Row>
      <Row className="image-hotel-wrapper">
        <Col className="image-wrapper">
          <img
            id="hotel-image"
            src={hotelDisplayData.imageUrl}
            alt={hotelDisplayData.hotelName}
          />
        </Col>
      </Row>
      <Card>
        <Row className="mb-3">
          <Col className="hotel-detail-col" id="hotel-details">
            {isLoadingDetail ? (
              <Spinner animation="grow" size="sm" />
            ) : (
              <>
                {checkInTime && (
                  <>
                    <span className="labels">Check-in: </span>
                    <span className="check-in">{checkInTime} |</span>
                    <span className="labels"> Check-out: </span>
                    <span>{checkOutTime}</span>
                  </>
                )}
              </>
            )}
          </Col>
          {hotelDisplayData.address && (
            <Col xs={12} className="hotel-detail-col" id="hotel-address">
              <span className="labels">Address: </span>
              <span className="hotel-address">
                <u>
                  <a
                    href={hotelDisplayData.addressMapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="addressMap"
                  >
                    {addressWithoutNull}
                  </a>
                </u>
              </span>
            </Col>
          )}
          {hotelDisplayData.phone && (
            <Col
              xs={12}
              className="phone-link hotel-detail-col"
              id="hotel-phone"
            >
              <span className="labels">Phone: </span>
              <span className="hotel-address">
                <a href={`tel:${hotelDisplayData.phone}`}>
                  {hotelDisplayData.phone}
                </a>
              </span>
            </Col>
          )}
        </Row>
        <Row className="pb-2">
          <Col className="hotel-detail-col" id="guest-details">
            {resDetails?.bedDesc && (
              <div>
                <span className="labels">Room: </span>{" "}
                <span>{resDetails?.bedDesc}</span>
              </div>
            )}
            {guestCount?.child > 0 ? (
              <div>
                <span className="labels">Guests: </span>{" "}
                <span>
                  {guestCount.adult} {guestCount.adult > 1 ? "Adults" : "Adult"}
                  , {guestCount.child}{" "}
                  {guestCount.child > 1 ? "Children" : "Child"}
                </span>
              </div>
            ) : (
              <div>
                <span className="labels">Guests: </span>{" "}
                <span>
                  {guestCount.adult} {guestCount.adult > 1 ? "Adults" : "Adult"}
                </span>
              </div>
            )}

            {resServices.map((service, idx) => {
              return (
                <div key={`add-on-${idx + 1}`} id={`add-on-${idx + 1}`}>
                  <span className="labels">Add-On: </span> {service}
                </div>
              );
            })}
          </Col>
        </Row>
        <Row className="ps-2">
          <Col className="mt-4 mb-5  d-flex res-links edit-buttons">
            {type === "upcoming" ? (
              <>
                {resApiDetails && !cancelID && (
                  <EditReservationButton
                    memberId={memberId}
                    reservation={resApiDetails}
                    hotel={hotel}
                    confirmationID={reservation.crs_reservation_id}
                  />
                )}
                {!cancelID && (
                  <Button
                    id="cancellation-button"
                    variant="link"
                    size="sm"
                    onClick={handleCancelReservation}
                  >
                    Cancel Reservation
                  </Button>
                )}
              </>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {isLoadingDetail ? (
          <Spinner animation="grow" size="sm" />
        ) : (
          <>
            {totalCostStr && (
              <Row className="ms-0 me-0 ps-2">
                <Col className="border-top border-bottom pt-3 pb-3 ps-0 pe-0 d-flex justify-content-between total-cost">
                  <span className="d-inline">Total For Stay:</span>
                  <span id="total-cost-per-stay" className="d-inline ">
                    {reservation?.redeemedPoints &&
                    reservation?.redeemedPoints > 0
                      ? `${reservation?.redeemedPoints} pts`
                      : isRedemptionReservation && pointsUsed
                      ? `${pointsUsed} pts`
                      : totalCostStr}
                  </span>
                </Col>
              </Row>
            )}
          </>
        )}
      </Card>
    </Container>
  );
};

export default ManageReservationCard;
