import React, { useState, useEffect } from "react";
import moment from "moment-mini";
import { IReservationProps } from "../pageHelpers/Reservation/ReservationProps";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
import SubTitle from "../components/global/SubTitle/SubTitle";
import ManageReservationCard from "../components/common/ManageReservationCard/ManageReservationCard";
import ReservationCancelDialog from "../components/common/ReservationCancelDialog/ReservationCancelDialog";
import { ReservationContainer } from "../pageHelpers/Reservation/ReservationHelpers";
import { resParseDateFormat } from "../services/dates";
import { useSelector } from "react-redux";
import { checkGuestUserExist } from "../services/loyalty";
import { Spinner } from "react-bootstrap";
import { navigate } from "gatsby";

const Reservation = ({ location }: IReservationProps) => {
  const [res, setRes] = useState(
    location.state ? location.state.reservation : null
  );
  const lastName = location.state ? location.state.lastName : null;
  const [memberID, setMemberID] = useState(null);
  const [isMemberIdLoading, setisMemberIdLoading] = useState(true);
  const [stayType, setStayType] = useState({
    typeCode: "",
    typeStr: "",
  });
  // const guestName = res?.details?.ResGuests[0].GivenName;
  const guestName = res?.details?.guests[0].givenName;
  //const memberID = res?.details?.ResGuests[0].MembershipID;

  const [reservationCancelDialogConfig, setReservationCancelDialogConfig] =
    useState({
      openDialog: false,
      res: null,
    });

  const onDialogClose = (cancelReq: { status: string; cancelID: any }) => {
    setReservationCancelDialogConfig({
      openDialog: false,
    });
    if (cancelReq && cancelReq.status == "success") {
      setStayType({
        typeCode: "cancelled",
        typeStr: "Cancelled Stay",
      });
      setRes({
        ...res,
        details: { ...res.details, CancelID: cancelReq.cancelID },
      });
    }
  };

  useEffect(() => {
    if (!res) {
      navigate("/manage-reservation");
    }
  }, [res]);

  const checkUserexist = async (email: string) => {
    try {
      const result: any = await checkGuestUserExist(email);
      if (result) {
        //An account with that email already exists.
        if (result?.statusCode === "200") {
          setMemberID(result.userId);
        }
        setisMemberIdLoading(false);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("error in check user ===== ", err);
    }
  };

  const checkUser = async () => {
    await checkUserexist(res?.details?.guests[0].email);
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    let type;
    if (res) {
      if ((res.details && res.details.cancelId) || res.type == "cancelled") {
        type = {
          typeCode: "cancelled",
          typeStr: "Cancelled Stay",
        };
      } else {
        if (moment(res.arrival, resParseDateFormat).isBefore()) {
          type = {
            typeCode: "recent",
            typeStr: "Past Stay",
          };
        } else {
          type = {
            typeCode: "upcoming",
            typeStr: "Upcoming Stay",
          };
        }
      }
      setStayType(type);
    }
  }, [res]);

  const getDateArray = (date: Date) => {
    const day = date.toLocaleString("en-us", { day: "numeric" });
    const month = date.toLocaleString("en-us", { month: "long" });
    const year = date.toLocaleString("en-us", { year: "numeric" });

    return [day, month, year];
  };

  const dateFormatter = (reservation: any) => {
    const arrival = new Date(reservation.arrival);
    const departure = new Date(reservation.departure);

    const arrivalStr = getDateArray(arrival);
    const departureStr = getDateArray(departure);
    const finalDateString =
      arrivalStr[1] +
      " " +
      arrivalStr[0] +
      ", " +
      arrivalStr[2] +
      " - " +
      departureStr[1] +
      " " +
      departureStr[0] +
      ", " +
      departureStr[2];

    return finalDateString;
  };

  return res && stayType.typeCode ? (
    <Layout hideSearchBar={true}>
      <SEO title={`Reservation`} noIndex={true} noFollow={true} />
      <ReservationContainer>
        <Row>
          <Col className="aside">
            <SubTitle className="sub-title-reservation">
              Your Reservation
            </SubTitle>
            <h3
              id={"member-name"}
              className={`title-reservation ${memberID ? "mb-2" : ""}`}
            >
              Hello, {guestName}
            </h3>
            {!isMemberIdLoading ? (
              memberID && (
                <div className="title-member-number mb-4" id="member-id">
                  Member Number: <strong>{memberID}</strong>{" "}
                </div>
              )
            ) : (
              <Spinner animation={"border"} size="sm" className="m-2" />
            )}{" "}
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="my-stays">
              {" "}
              <div className="my-stay-start"></div>My Stays
            </div>
            <div className="stay-type" id="stay-type">
              {stayType.typeStr}
            </div>
            <div className="reservation-date" id="reservation-date">
              {dateFormatter(res)}
            </div>
          </Col>
        </Row>
        <Row className="g-0">
          <Col>
            <ManageReservationCard
              reservation={res}
              type={stayType.typeCode}
              onCancel={setReservationCancelDialogConfig}
              memberId={memberID}
            />
          </Col>
        </Row>
        <ReservationCancelDialog
          config={reservationCancelDialogConfig}
          onHide={onDialogClose}
        />
      </ReservationContainer>
    </Layout>
  ) : null;
};

export default Reservation;
