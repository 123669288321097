import * as React from "react";
import Container from "react-bootstrap/Container";
import { container, infoWrapper, title4, title6 } from "./Reservation.module.scss";

export const ReservationContainer = (props: any) => {
    return (<Container className={`${container} pt-5 pb-5 justify-content-center`} fluid="sm">{props.children}</Container>);
};

export const InfoWrapper = (props: any) => {
    return (<div className={infoWrapper}>{props.children}</div>);
};

export const Title4 = (props: any) => {
    return (<h4 className={`${title4} pt-3`}>{props.children}</h4>);
};

export const Title6 = (props: any) => {
    return (<h6 className={`${title6} pt-3`}>{props.children}</h6>);
};



